import {
  MutationOptions,
  QueryClient,
  useMutation as useMutationBase,
  useQueries,
  UseQueryOptions,
  UseQueryResult
} from "react-query3";
import axios, { AxiosError } from "axios";
import { SchemaName } from "components/ui/Form/types";
import { toRelativeUrl } from "./utils";
const baseUrl = toRelativeUrl("/api/v1");

type Awaited<T> = T extends PromiseLike<infer U> ? Awaited<U> : T;

export function useQueriesTyped<TQueries extends readonly UseQueryOptions[]>(
  queries: [...TQueries]
): {
    [ArrayElement in keyof TQueries]: UseQueryResult<
      TQueries[ArrayElement] extends { select: infer TSelect }
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
      TSelect extends (data: any) => any
      ? ReturnType<TSelect>
      : never
      : Awaited<
        ReturnType<
          NonNullable<
            Extract<TQueries[ArrayElement], UseQueryOptions>["queryFn"]
          >
        >
      >
    >;
  } {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useQueries(
    queries as UseQueryOptions<unknown, unknown, unknown>[]
  ) as any;
}

const defaultQueryFn = async ({ queryKey }) => {
  if (queryKey == null || queryKey === undefined || (Array.isArray(queryKey) && queryKey[0] === undefined)) return null;

  const [_key] = queryKey;
  let { data } = await axios.get(`${baseUrl}${_key}`);

  if (_key === "/license/feature") return data;

  let regex = new RegExp(".?([.|a-zA-Z0-9 -]{1,50})$");

  const getParent = (parent) => {
    if (_key === "/endpoint/doc") {
      return _key;
    }
    return parent || _key;
  }

  const getSchema = (item) => {
    if (_key === "/settings/authentication") {
      return "authentication";
    }
    if (item?.modelId) {
      return "dashboardPage";
    }
    if (_key === "/endpoint/doc") {
      return "endpointDocumentation";
    }
    return _key.split("/")[1] as SchemaName;
  };

  if (Array.isArray(data)) {
    data.forEach((item, index) => {
      if (item?.environment) {
        item["environment"] = item?.environment === "Default" ? null : item?.environment
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let [parent, child, empty] = _key.split(regex);
      if (typeof item !== "string") {
        data[index] = {
          ...item,
          resourceInfo: {
            parent: getParent(parent),
            self:
              _key === "/script"
                ? `${_key}/${item.fullPath}`
                : item?.modelId ? `${_key}/${item.modelId}`
                  : Number.isNaN(item?.id) ? `${parent}/${child}` : `${_key}/${item.id}`,
            schemaName: getSchema(item)
          },
        };
      }
    });
    data?.forEach((item) => {
      queryClient.setQueryData(item?.resourceInfo?.self, item);
    });
  }

  if (!Array.isArray(data)) {
    if (data?.environment) {
      data["environment"] = data?.environment === "Default" ? null : data?.environment
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let [parent, child, empty] = _key.split(regex);

    let items = _key.split("/");

    if (typeof data !== "string" && typeof data !== "number") {
      data = {
        ...data,
        resourceInfo: {
          parent: '/' + items[1],
          self: _key ? `${_key}` : `${parent}/${child}`,
          schemaName:
            parent === "/settings/authentication"
              ? ("authentication" as SchemaName)
              : (items[1] as SchemaName),
        },
      };
    }
  }

  if (_key === "/settings") return data[0];
  return data;
};

const defaultMutationFn = async (variables) => {
  const { key, action, ...rest } = variables;
  let regex = new RegExp(".?([.|a-zA-Z0-9 -]{1,50})$");
  let [parent] = key?.split(regex);

  let config = {
    headers: {
      "content-type": "text",
    },
    transformRequest: [
      (data) => {
        return data?.resource;
      },
    ],
  };

  switch (action) {
    case "create": {
      const { data } = await axios.post(`${baseUrl}${key}`, rest);
      return data;
    }
    case "update": {
      const { data } = await axios.put(
        `${baseUrl}${key}`,
        parent === "/configuration"
          ? rest
          : rest?.resource
            ? rest.resource
            : { ...rest },
        parent === "/configuration" ? config : axios.defaults
      );
      return data;
    }
    case "delete": {
      const { data } = await axios.delete(`${baseUrl}${key}`);
      return data;
    }
    case "get": {
      const { data } = await axios.get(`${baseUrl}${key}`);
      return data;
    }
    default:
      throw new Error(
        "you need to used one of the following actions: create, update, delete"
      );
  }
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      refetchOnWindowFocus: false,
    },
    mutations: {
      mutationFn: defaultMutationFn,
      onSuccess: (data, { key }) => {
        let parent = key?.split("/")[1];
        queryClient.refetchQueries(`/${parent}`, {
          inactive: true,
          active: true,
          stale: true,
        });
      },
    },
  },
});

export default queryClient;

export function useMutation<T>(
  config?: MutationOptions<
    T,
    AxiosError<T>,
    T & { key: string; action: string },
    T
  >
) {
  return useMutationBase<
    T,
    AxiosError<T>,
    T & { key: string; action: string },
    T
  >({ ...config });
}
