import { AppReducerState } from "components/context/app/Context";
import queryClient from "components/utils/queryClient";
import { Dashboard } from "../../../types";
import { Field } from "./Form";
import { CustomSchema } from "./types";

export const dashboardSchema: CustomSchema<Dashboard>[] = [
  {
    name: "name",
    label: "Name",
    component: "input",
    required: true,
    uniqe: true,
  },
  {
    name: "description",
    label: "Description",
    component: "input",
  },
  {
    name: "baseUrl",
    label: "Url",
    component: "input",
    required: true,
    uniqe: true,
    initialValue: "/dashboard",
  },
  {
    name: "environment",
    label: "Environment",
    component: "environment",
    initialValue: "Default",
  },
  {
    name: "dashboardFramework",
    label: "Framework",
    component: "frameworkSelectBox",
    hideInCreateMode: true,
  },
  {
    name: "template",
    label: "Template",
    component: "dashboardTemplateSelect",
    initialValue: "Blank.ps1",
    hideInEditMode: true,
  },
  {
    name: "credential",
    label: "Run As",
    tooltip: "Select the credentials to run the dashboard",
    component: "credential",
    initialValue: "Default",
    required: false,
    condition: (context: AppReducerState) => {
      return !context.settings?.hideRunAs;
    }
  },
  {
    noStyle: true,
    shouldUpdate: (prevValues, curValues) => {
      return prevValues.view !== curValues.view;
    },
    children: ({ getFieldValue }) => {
      let selectedView = getFieldValue("view");
      return selectedView === "import" ? (
        <Field
          component="input"
          name="filePath"
          label="Path"
          required={true}
          uniqe={true}
          preserve={false}
        />
      ) : null;
    },
  },
  {
    name: "tag",
    label: "Tags",
    component: "tag",
    initialValue: []
  },
  {
    name: "maintenance",
    label: "Maintenance Mode",
    tooltip: "Disable this dashboard for all users except Administrators.",
    component: "switch",
    valuePropName: "checked",
    required: false,
    uniqe: false,
    hideInCreateMode: true,
  },
  {
    name: "disableInteractiveHost",
    label: "Disable Interactive Host",
    tooltip: "Disable features such as Write-Progress, Read-Host and prompt for choice.",
    component: "switch",
    valuePropName: "checked",
    required: false,
    uniqe: false,
    hideInCreateMode: true,
  },
  {
    name: "verboseErrorMessages",
    label: "Verbose Error Messages",
    tooltip: "Displays verbose error messages in toasts.",
    component: "switch",
    valuePropName: "checked",
    required: false,
    uniqe: false,
    hideInCreateMode: true,
  },
  {
    name: "authenticated",
    label: "Authentication",
    component: "authSwitch",
    valuePropName: "checked",
    required: false,
    uniqe: false,
  },
  {
    noStyle: true,
    shouldUpdate: (prevValues, curValues) => {
      return (
        prevValues.authenticated !== curValues.authenticated ||
        curValues.authenticated === true
      );
    },
    children: ({ getFieldValue }) => {
      const name = getFieldValue("name")
      let defaultRoles = queryClient.getQueryData<Dashboard[]>(`/dashboard`)?.find(dashboard => dashboard?.name === name)?.role
      return getFieldValue("authenticated") === true ? (
        <Field
          component="roleTags"
          name="role"
          initialValue={defaultRoles || null}
          label="Role"
          preserve={true}
          dependencies={["authenticated"]}
          uniqe={false}
        />
      ) : null;
    },
  },
  {
    name: "autoStart",
    label: "Auto Start",
    component: "switch",
    valuePropName: "checked",
    initialValue: true,
    hideInCreateMode: true,
  },
  {
    name: "autoDeploy",
    label: "Auto Deploy",
    component: "switch",
    valuePropName: "checked",
    initialValue: true,
    hideInCreateMode: true,
  },
];
