import React from "react";
import { Button, Result } from "antd";
import { toRelativeUrl } from "components/utils/utils";

export default function LoggedOutPage() {
    return (
        <Result
            title="Logged Out"
            subTitle="You have been logged out."
            extra={[
                <Button type="primary" onClick={() => window.location.href = toRelativeUrl("/admin")}>
                    Login
                </Button>
            ]
            }
        />
    );
}
