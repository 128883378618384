import React, { useState } from "react";
import { Layout, Modal, Result, Skeleton } from "antd";
import { useQuery } from "react-query3";
import { Settings } from "types";
import { Route, Routes } from "react-router-dom";
import Footer from "./ui/Footer";
import UAMenu from "./menus/menu";
import PrimaryLayout from "./PrimaryLayout";
import useConnection from "./useConnection";
import UAMenuHeader from "./menus/menu_header";
import DefaultRoute from "./routes/DefaultRoute";
import { useIdleTimer } from 'react-idle-timer';
import { AppContext } from "./context/app/Context";
import Tokens from "./settings/tokens";
import DesktopRoute from "./routes/DesktopRoutes";
import { toRelativeUrl } from "./utils/utils";

const Pages = React.lazy(() => import("components/interfaces/Pages"));
const ApisRoutes = React.lazy(() => import("components/routes/ApisRoute"));
const AutomationsRoutes = React.lazy(
  () => import("components/routes/AutomationsRoute")
);
const DashboardsRoutes = React.lazy(
  () => import("components/routes/DashboardsRoute")
);

const PlatformRoutes = React.lazy(
  () => import("components/routes/PlatformRoute")
);

const SettingsRoutes = React.lazy(
  () => import("components/routes/SettingsRoute")
);
const SecurityRoutes = React.lazy(
  () => import("components/routes/SecurityRoute")
);
const Home = React.lazy(
  () => import("components/home/home")
);
const Notifications = React.lazy(
  () => import("components/notifications/notifications")
);
const NotFoundPage = React.lazy(() => import("components/pages/NotFoundPage"));
const UpdatePage = React.lazy(() => import("components/updates/update"));
const UnAuthorizedPage = React.lazy(() => import("components/pages/UnAuthorizedPage"));

const openNotification = () => {
  Modal.warning({
    title: 'Session Expired',
    content: 'Your session has expired.',
    okText: 'Login',
    onOk: () => window.location.reload(),
  });
};

export default function AuthorizedView() {
  useConnection();
  const { data } = useQuery<Settings[]>('/settings/public');

  const [adminConsole, setAdminConsole] = React.useState(false);
  const [adminConsoleLoading, setAdminConsoleLoading] = React.useState(true);
  const [isAlive, setIsAlive] = useState(false);
  const { data: alive } = useQuery<any>('/alive', {
    refetchInterval: 1000,
    enabled: !isAlive,
    onSuccess(data) {
      setIsAlive(!data.loading || data.hasError);
    },
  });
  var sessionTimeout = data ? data[0].sessionTimeout : 25;
  if (sessionTimeout === 0) sessionTimeout = 25;

  useIdleTimer({
    timeout: sessionTimeout * 1000 * 60,
    onIdle: openNotification,
    stopOnIdle: true
  });

  if (!alive || alive.loading) {
    return <Result title={alive?.hasError ? "PowerShell Universal Failed to Load" : "PowerShell Universal is loading..."} subTitle={<pre>{alive?.loadingInfo}</pre>} extra={alive?.hasError || <Skeleton active />} status={alive?.hasError ? "error" : "info"} />
  }

  fetch(toRelativeUrl('/api/v1/accessControl/adminConsole')).then(x => {
    x.text().then(s => {
      setAdminConsole(s === "true");
    })
  }).finally(() => {
    setAdminConsoleLoading(false);
  })

  return (
    <AppContext.Consumer>
      {context => {
        if (context.isUserDataLoading || context.isSsoDataLoading || adminConsoleLoading) {
          return (<Skeleton />)
        }

        if (!context.userData) {
          if (context.sso.enabled) {
            window.location.href = toRelativeUrl("/api/v1/auth/redirect?returnUrl=" + encodeURI(window.location.pathname));
          }
          else {
            window.location.href = toRelativeUrl("/login?returnUrl=" + encodeURI(window.location.pathname));
          }

          return;
        }

        if (!adminConsole) {
          return <UnAuthorizedPage />
        }


        return (
          <Layout style={{ height: "100vh" }}>
            <UAMenuHeader />
            <Layout hasSider style={{ height: '100%' }}>
              <UAMenu />
              <PrimaryLayout>
                <Routes>
                  <DefaultRoute />
                  <Route path="home" element={<Home />} />
                  <Route path="notifications" element={<Notifications />} />
                  <Route path="apis/*" element={<ApisRoutes />} />
                  <Route path="automation/*" element={<AutomationsRoutes />} />
                  <Route path="dashboards/*" element={<DashboardsRoutes />} />
                  <Route path="desktop/*" element={<DesktopRoute />} />
                  <Route path="pages" element={<Pages />} />
                  <Route path="security/tokens" element={<Tokens />} />
                  <Route path="settings/*" element={<SettingsRoutes />} />
                  <Route path="security/*" element={<SecurityRoutes />} />
                  <Route path="platform/*" element={<PlatformRoutes />} />
                  <Route path="update" element={<UpdatePage />} />
                  <Route path="unauthorized" element={<UnAuthorizedPage />} />
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </PrimaryLayout>
            </Layout>
            <Footer />
          </Layout>
        )
      }}
    </AppContext.Consumer>
  );
}
