import { QueryClientProvider } from "react-query3";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";
import queryClient from "./utils/queryClient";
import AppProvider from "./context/app/Provider";
import AuthorizedView from "./AuthorizedView";
import Login from "./pages/login";
import MaintenanceMode from "./pages/MaintenanceMode";
import PageDashboard from "./interfaces/PageDashboard";
import Loader from "./utils/Loader";
import UnAuthorizedPage from "./pages/UnAuthorizedPage";
import { toRelativeUrl } from "./utils/utils";
import './localization/i18n';
import LoggedOutPage from "./pages/loggedOut";
const Editor = React.lazy(() => import("./dashboard/Editor"));

const themes = {
  dark: `${process.env.PUBLIC_URL}/dark.css`,
  light: `${process.env.PUBLIC_URL}/light.css`,
}

const App = () => {
  const currentTheme = localStorage.getItem("theme");
  return (


    <ThemeSwitcherProvider
      defaultTheme={currentTheme || "light"}
      insertionPoint="inject-styles-here"
      themeMap={themes}
    >
      <QueryClientProvider client={queryClient}>
        <AppProvider>
          <Loader>
            <BrowserRouter>
              <Routes basename={toRelativeUrl("")}>
                <Route path="admin/*" element={<AuthorizedView />} />
                <Route path="admin/dashboards/editor/:id" element={<Editor />} />
                <Route path="login/*" element={<Login />} />
                <Route path="maintenance" element={<MaintenanceMode />} />
                <Route path="unauthorized" element={<UnAuthorizedPage />} />
                <Route path="loggedout" element={<LoggedOutPage />} />
                <Route path="*" element={<PageDashboard />} />
              </Routes>
            </BrowserRouter>
          </Loader>
        </AppProvider>
      </QueryClientProvider>
    </ThemeSwitcherProvider>

  );
};

export default App;
