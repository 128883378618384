import React, { lazy } from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";
import useAppStore from "../context/app/Hooks";
import { useQuery } from "react-query3";
import queryClient from "./../utils/queryClient";
import { toRelativeUrl } from "components/utils/utils";

const UnAuthorizedImage = lazy(
  () =>
    import(
      /* webpackChunkName: 'UnauthorizedImage' */ "./unauthorized-image.js"
    )
);

export default function UnAuthorizedPage() {
  const navigate = useNavigate();
  const appContext = useAppStore();
  const { refetch: logoff } = useQuery<string>("/signout", {
    enabled: false,
    onSuccess: () => queryClient.clear(),
  });

  return (
    <Result
      title="Unauthorized Access"
      subTitle="You are not authorized to view this page."
      icon={<UnAuthorizedImage />}
      extra={[
        !appContext.userData?.username && <Button type="primary" onClick={() => window.location.reload()}>
          Login
        </Button>,
        appContext.userData?.username && <Button type="primary" onClick={() => logoff().then(() => (window.location.href = toRelativeUrl("/login")))}>
          Log Off
        </Button>,
        <Button type="primary" onClick={() => () => navigate(-1)}>
          Back
        </Button>
      ]
      }
    />
  );
}
