import { Environment } from "../../../types";
import { CustomSchema } from "./types";

export const environmentSchame: CustomSchema<Environment>[] = [
  {
    name: "name",
    label: "Name",
    component: "input",
    required: true,
    rules: [{
      required: true,
      message: "Environment name can't be empty."
    }],
    tooltip: "Name of the environment. This is how the environment will be shown and referenced through out the platform."
  },
  {
    name: "description",
    label: "Description",
    component: "input",
    required: true,
    tooltip: "A user-friendly description of the environment."
  },
  {
    name: "path",
    label: "Path",
    component: "input",
    required: true,
    tooltip: "The path to the PowerShell executable. Ignored for the Integrated environment."
  },
  {
    name: "arguments",
    label: "Arguments",
    component: "input",
    required: false,
    tooltip: "Arguments to pass to the PowerShell process for this environment. Ignored for the Integrated environment."
  },
  {
    name: "variables",
    label: "Variables",
    component: "variables",
    required: false,
    tooltip: "Variables to make available in this environment. A * indicates all variables. You can also use * in variable names like 'PROD_*'",
    initialValue: ["*"]
  },
  {
    name: "modules",
    label: "Modules",
    component: "selectTag",
    required: false,
    tooltip: "Modules to load into this environment."
  },
  {
    name: "startupScript",
    label: "Startup Scripts",
    component: "selectTag",
    required: false,
    tooltip: "Scripts to execute when creating runspaces in this environment."
  },
  {
    name: "persistentRunspace",
    label: "Persistent Runspace",
    component: "switch",
    valuePropName: "checked",
    tooltip: "Persistent runspaces prevent the environment from resetting the runspace state on every execution",
    required: false,
  },
  {
    name: "maxRunspaces",
    label: "Max Runspaces",
    component: "inputNumber",
    tooltip: "The maximum number of runspaces to allocate to a runspace pool.",
    required: false,
  },
  {
    name: "maxVirtualMemory",
    label: "Maximum Virtual Memory (MBs)",
    component: "inputNumber",
    tooltip: "The maximum virtual memory allowed by features using this environment. The environment will be restarted if it reaches this threshold.",
    required: false,
  },
  {
    name: "recycleSchedule",
    label: "Recycle Schedule",
    component: "input",
    required: false,
    tooltip: "The interval at which to restart features using this environment. This will not affect terminals or jobs."
  },
  {
    name: "disableImplicitWinCompat",
    label: "Disable Implicit Windows Compatibility",
    component: "switch",
    valuePropName: "checked",
    required: false,
    hideInCreateMode: true,
    tooltip: "Disables implicit Windows compatibility for this environment. This will prevent modules from being loaded from the Windows PowerShell module path."
  },
  {
    name: "enableDebugger",
    label: "Enable Debugger",
    component: "switch",
    valuePropName: "checked",
    tooltip: "Enable the PowerShell debugger in this environment.",
    required: false,
  },
  {
    name: "reload",
    label: "Reload Resources",
    component: "switch",
    valuePropName: "checked",
    required: false,
    hideInCreateMode: true,
    tooltip: "Reloads APIs, dashboards and security resources using this environment. If false, you will need to restart those resources at another time to pick up the changes."
  },
];
