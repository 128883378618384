import React, { useState } from "react";
import { useQuery } from "react-query3";
import { Alert, Button, PageHeader, Space, Switch } from "antd";
import { Modal } from "../ui/Modal";
import { AppToken, CustomColumns, Settings } from "../../types";
import Table from "../ui/Table";
import DeleteButton from "../ui/DeleteButton";
import CopyButton from "../ui/CopyButton";
import NewAppTokenModal from "./NewAppTokenModal";
import { formatDate } from "components/utils/utils";
import { LinkOutlined } from "@ant-design/icons";
import DocsButton from "components/docsButton";
import useAppStore from "components/context/app/Hooks";

export default function Tokens() {
  const appContext = useAppStore();
  const [newToken, setNewToken] = useState({ token: '' });

  const { data: tokens, isLoading, refetch } = useQuery<AppToken[]>(
    "/apptoken",
    {
      enabled: false,
      refetchOnWindowFocus: false,
    }
  );

  const { data: settings } = useQuery<Settings>(
    "/settings",
    {
      enabled: false,
      refetchOnWindowFocus: false,
    }
  );

  const [showRevoked, setShowRevoked] = useState(false);

  const onSuccess = (item) => {
    if (appContext.settings.enhancedAppTokenSecurity) {
      setNewToken(item);
    }
  }

  function setHidden(record: AppToken) {
    return record?.revoked;
  }

  const columns: CustomColumns<AppToken>[] = [
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      editable: false,
    },
    {
      title: "Identity",
      dataIndex: ["identity", "name"],
      key: "identity",
      editable: false,
    },
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      editable: false,
      render: (text, record) => formatDate(text),
    },
    {
      title: "Expiration",
      dataIndex: "expiration",
      editable: false,
      key: "expiration",
      render: (text, record) => formatDate(text),
    },
    {
      title: "Revocation Date",
      dataIndex: "revocation Date",
      editable: false,
      key: "revocation",
      render: (text, record) => record?.revoked && formatDate(text),
    },
    {
      title: "Role",
      dataIndex: "role",
      editable: false,
      key: "role",
    },
    {
      title: "Revoked",
      dataIndex: "revoked",
      editable: false,
      key: "revoked",
      render: (text, record) => (record.revoked ? "Yes" : "No"),
    },
    {
      width: "fit-content",
      dataIndex: "actions",
      editable: false,
      render: (text, record) => {
        return (
          <Space>
            {!settings?.enhancedAppTokenSecurity && <CopyButton hidden={setHidden(record)} text={record.token} allowedWithOneWayGitSync={true} ignoreRoleGuard />}
            <DeleteButton hidden={setHidden(record)} resource={record} allowedWithOneWayGitSync={true} ignoreRoleGuard noDemo />
          </Space>
        );
      },
    },
  ];

  return (
    <PageHeader title="Application Tokens" subTitle="Application tokens for invoking the PowerShell Universal API." extra={[
      <Switch checkedChildren="Show Revoked" unCheckedChildren="Hide Revoked" onChange={e => setShowRevoked(e)} checked={showRevoked} />,
      <Button icon={<LinkOutlined />} href="/swagger" target="_blank">API Documentation</Button>,
      <DocsButton url="config/security/app-tokens" />
    ]}>

      {newToken?.token && <Alert type="success" message="New app token created. Please copy this token as it will not be available to view again." description={newToken.token} showIcon action={
        <CopyButton text={newToken.token} allowedWithOneWayGitSync={true} ignoreRoleGuard />
      } closable />}

      <Table
        title="Tokens"
        actions={[
          <Modal>
            <NewAppTokenModal onSuccess={onSuccess} />
          </Modal>,
        ]}
        data={tokens?.filter(m => showRevoked || !m.revoked)}
        columns={columns}
        loading={isLoading}
        queryFn={refetch}
      />
    </PageHeader >
  );
}
