import { Variable } from "../../../types";
import { Field } from "./Form";
import { CustomSchema } from "./types";

export const variableSchema: CustomSchema<Variable>[] = [
  {
    name: "variableType",
    label: "Variable Type",
    component: "variableTypeSelector",
    required: true,
    uniqe: false,
    initialValue: "simple",
    hideInEditMode: true,
  },
  {
    name: "name",
    label: "Name",
    tooltip: "Variable name.",
    component: "input",
    required: true,
    uniqe: true,
  },
  {
    name: "description",
    label: "Description",
    tooltip: "Description of the variable.",
    component: "input",
    required: false,
    uniqe: false,
  },
  {
    name: "type",
    hidden: true,
    style: { display: "none" },
  },
  {
    name: "displayValue",
    hidden: true,
    style: { display: "none" },
  },
  {
    uniqe: false,
    noStyle: true,
    shouldUpdate: (prevValues, curValues) => {
      return prevValues.variableType !== curValues.variableType;
    },
    children: ({ getFieldValue }) => {
      if (getFieldValue("variableType") === "secret") {
        return (
          <Field
            component="secretTypeSelector"
            name="type"
            label="Type"
            tooltip="Select the type of secret you want to create."
            preserve={false}
            initialValue="System.String"
          />
        );
      }
    },
  },
  {
    uniqe: false,
    noStyle: true,
    shouldUpdate: (prevValues, curValues) => {
      return prevValues.type !== curValues.type;
    },
    children: ({ getFieldValue }) => {
      if (
        getFieldValue("variableType") === "secret" &&
        getFieldValue("type") === "System.String"
      ) {
        return (
          <Field
            component="input"
            required={true}
            name="value"
            label="Value"
            tooltip="The value of this variable."
          />
        );
      }
    },
  },
  {
    uniqe: false,
    noStyle: true,
    shouldUpdate: (prevValues, curValues) => {
      return prevValues.type !== curValues.type;
    },
    children: ({ getFieldValue }) => {
      if (getFieldValue("variableType") === "simple") {
        return (
          <Field
            component="variableTypeSelect"
            name="type"
            required={true}
            label="Type"
            tooltip="The type of variable."
            initialValue={"System.String"}
            preserve={false}
          />
        );
      }
    },
  },
  {
    uniqe: false,
    noStyle: true,
    shouldUpdate: (prevValues, curValues) => {
      return prevValues.type !== curValues.type;
    },
    children: ({ getFieldValue }) => {
      if (getFieldValue("variableType") === "simple" && getFieldValue("type") === "System.String") {
        return (
          <Field
            component="input"
            name="value"
            required={true}
            label="Value"
            tooltip="The value of this variable."
            preserve={false}
            initialValue={getFieldValue("displayValue")}
          />
        );
      }
    },
  },
  {
    uniqe: false,
    noStyle: true,
    shouldUpdate: (prevValues, curValues) => {
      return prevValues.type !== curValues.type;
    },
    children: ({ getFieldValue }) => {
      if (getFieldValue("variableType") === "simple" && getFieldValue("type") === "System.Boolean") {
        return (
          <Field
            component="switch"
            name="value"
            required={true}
            label="Value"
            tooltip="The value of this variable."
            preserve={false}
            initialValue={getFieldValue("displayValue") === "True"}
            valuePropName={"checked"}
          />
        );
      }
    },
  },
  {
    uniqe: false,
    noStyle: true,
    shouldUpdate: (prevValues, curValues) => {
      return prevValues.type !== curValues.type;
    },
    children: ({ getFieldValue }) => {
      if (getFieldValue("variableType") === "simple" && getFieldValue("type") === "System.Int32") {
        return (
          <Field
            component="input"
            name="value"
            required={true}
            label="Value"
            tooltip="The value of this variable."
            preserve={false}
          />
        );
      }
    },
  },
  {
    uniqe: false,
    noStyle: true,
    shouldUpdate: (prevValues, curValues) => {
      return prevValues.type !== curValues.type;
    },
    children: ({ getFieldValue }) => {
      if (getFieldValue("variableType") === "simple" && getFieldValue("type") === "System.Collections.ArrayList") {
        return (
          <Field
            component="selectAsTags"
            name="value"
            required={true}
            label="Value"
            tooltip="The value of this variable."
            preserve={false}
            initialValue={getFieldValue("displayValue")?.split(" ")}
          />
        );
      }
    },
  },
  {
    uniqe: false,
    noStyle: true,
    shouldUpdate: (prevValues, curValues) => {
      return prevValues.type !== curValues.type;
    },
    children: ({ getFieldValue }) => {
      if (
        getFieldValue("variableType") === "secret" &&
        getFieldValue("type") === "PSCredential"
      ) {
        return (
          <Field
            component="input"
            required={true}
            name="userName"
            label="User Name"
            preserve={false}
          />
        );
      }
    },
  },
  {
    uniqe: false,
    noStyle: true,
    shouldUpdate: (prevValues, curValues) => {
      return prevValues.type !== curValues.type;
    },
    children: ({ getFieldValue }) => {
      if (
        getFieldValue("variableType") === "secret" &&
        getFieldValue("type") === "PSCredential"
      ) {
        return (
          <Field
            component="password"
            required={true}
            name="password"
            label="Password"
            preserve={false}
          />
        );
      }
    },
  },
  {
    uniqe: false,
    noStyle: true,
    shouldUpdate: (prevValues, curValues) => {
      return prevValues.type !== curValues.type;
    },
    children: ({ getFieldValue }) => {
      if (
        getFieldValue("variableType") === "secret" &&
        getFieldValue("type") === "PSCredential"
      ) {
        return (
          <Field
            component="checkbox"
            valuePropName="checked"
            name="disableRunAsSupport"
            label="Disable Run As Support"
            tooltip="Disable using this credential for run as support."
            preserve={false}
          />
        );
      }
    },
  },
  {
    uniqe: false,
    noStyle: true,
    shouldUpdate: (prevValues, curValues) => {
      return prevValues.variableType !== curValues.variableType;
    },
    children: ({ getFieldValue }) => {
      if (getFieldValue("variableType") === "secret") {
        return (
          <Field
            component="vault"
            required={true}
            name="vault"
            label="Vault"
            initialValue={"Database"}
            preserve={false}
          />
        );
      }
    },
  },
];
