import { presetPrimaryColors } from "@ant-design/colors";
import { Space, Typography } from "antd";
import { toRelativeUrl } from "components/utils/utils";
import React from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { useQuery } from "react-query3";
import { Link } from "react-router-dom";
import { Settings } from 'types';
import { Helmet } from "react-helmet";

export default function Logo({ mode }) {
  const { currentTheme } = useThemeSwitcher();
  const { data: settingsArr, isLoading } = useQuery<Settings[]>("/settings/public");

  if (isLoading) return <></>

  const settings = settingsArr[0];

  return (
    <Space>
      {settings?.adminConsoleTitle && <Helmet>
        <title>{settings.adminConsoleTitle}</title>
      </Helmet>}
      <Link to={toRelativeUrl("/admin/home")}>
        <div style={{ width: settings?.useLogoSize ? null : 28, margin: "0 18px", flex: "0" }}>
          <img
            alt="powershell universal logo"
            style={{ width: "inherit", height: "inherit" }}
            src={settings?.adminConsoleLogo || toRelativeUrl("admin/logo.png")}
          />
        </div>
      </Link>
      <Space direction="horizontal" size={0}>
        {
          settings?.adminConsoleTitle && (<Typography.Text
            style={{
              fontFamily: "SFProDisplay-Semibold",
              fontSize: 14,
              color: currentTheme === 'dark' ? '#fff' : '#1f1f1f'
            }}
          >
            <Link to={toRelativeUrl("/admin/home")}>
              {settings?.adminConsoleTitle}
            </Link>
          </Typography.Text>)
        }
        {
          !settings?.adminConsoleTitle && (
            <Link to={toRelativeUrl("/admin/home")}>
              <Typography.Text
                style={{
                  fontFamily: "SFProDisplay-Black",
                  fontSize: 14,
                  color: presetPrimaryColors["blue"]
                }}
              >
                PowerShell
              </Typography.Text>
              <Typography.Text
                style={{
                  fontFamily: "SFProDisplay-Semibold",
                  fontSize: 14,
                  color: currentTheme === 'dark' ? '#fff' : '#1f1f1f'
                }}
              >
                &nbsp;Universal
              </Typography.Text>
              {mode === "Desktop" && (
                <Typography.Text
                  style={{
                    fontFamily: "SFProDisplay-Black",
                    fontSize: 14,
                    color: presetPrimaryColors["blue"]
                  }}
                >
                  &nbsp;Desktop
                </Typography.Text>
              )}
            </ Link>
          )
        }

      </Space>
    </Space>
  );
}
